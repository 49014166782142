import React from "react";
import { graphql } from "gatsby";
import SolutionTemplate from "../../templates/solution-template";

const SolutionPage = ({ data: { solution, experts } }) => (
  <SolutionTemplate solution={solution} experts={experts.nodes} />
);

export default SolutionPage;

export const query = graphql`
  query ($id: String!, $language: String!) {
    solution: solutionsJson(id: { eq: $id }) {
      id: jsonId
      slug
      serviceId
      datePublished
      dateModified
      techs
      experts
      image
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    experts: allExpertsJson(sort: { fields: order }) {
      nodes {
        id
        linkedinUrl
        name
        role
        picture
      }
    }
  }
`;
